<template>
    <div class="body-custom" v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
                    <h3 class="card-label"><b class="title-header">テナント検索</b></h3>
                </div>
                <div>
                    <ButtonSearchMobile></ButtonSearchMobile>
                </div>
            </div>
            <div class="card-body">
                <div id="search-form">
                    <el-form
                        ref="ruleForm"
                        label-position="left"
                        class="demo-ruleForm demo-form"
                        @keyup.enter.native="onSearch"
                    >
                        <div class="row">
                            <div class="col-md-8 col-sm-12 col-xs-6">
                                <div class="row">
                                    <div class="col-md-3 col-6">
                                        <el-form-item label="テナント名" prop="tenant_name">
                                            <el-input
                                                name="tenant_name"
                                                autocomplete="on"
                                                placeholder="テナント名を入力"
                                                type="text"
                                                v-model="searchForm.tenant_name"
                                            ></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-3 col-6">
                                        <el-form-item label="ディレクトリ名" prop="tenant_dir">
                                            <el-input
                                                name="tenant_dir"
                                                autocomplete="on"
                                                placeholder="ディレクトリ名を入力"
                                                type="text"
                                                v-model="searchForm.tenant_dir"
                                            ></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-3 col-6">
                                        <el-form-item label="ステータス" prop="tenant_status">
                                            <el-select
                                                multiple
                                                style="width: 100%"
                                                v-model="searchForm.tenant_status"
                                            >
                                                <el-option
                                                    v-for="item in options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>

                                    <div class="col-12 text-right">
                                        <div class="button-submit-mobile" style="padding-top: 0">
                                            <el-button
                                                @click.prevent="onSearch"
                                                type="primary"
                                            >{{ $t("search") }}</el-button
                                            >
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4 col-xs-6 d-flex">
                                <div class="el-form-item">
                                    <label class="el-form-item__label">&nbsp; </label>
                                    <div class="el-form-item__content">
                                        <div class="el-input">
                                            <button @click.prevent="onSearch" class="btn btn-primary mr-2">
                                                {{ $t('search') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="el-form-item">
                                    <label class="el-form-item__label">&nbsp; </label>
                                    <div class="el-form-item__content">
                                        <div class="el-input">
                                            <button :disabled="listTenant.total <= 0" @click.prevent="exportExcel" class="btn btn-primary mr-2">{{ $t('export_csv') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </el-form>
                </div>
                <div id="data-table-list">
                    <div class="header-table-mobile">
                        <button @click="exportExcel"
                                type="button" class="btn btn-primary mr-2"
                                :disabled="listTenant.total <= 0"
                        >{{$t('export_csv')}}</button>
                    </div>
                    <div class="row float-right pr-5">
                        <my-pagination
                            v-if="listTenant.total"
                            :total-record="parseInt(listTenant.total)"
                            :current-page="parseInt(listTenant.current_page)"
                            :page-size-default="parseInt(listTenant.per_page)"
                            @handleCurrentChange="handleCurrentChange"
                            @handleSizeChange="handleSizeChange"
                        />
                    </div>
                    <el-table
                        v-if="listTenant.data && listTenant.total > 0"
                        class="mb-5"
                        :data="listTenant.data"
                        style="width: 100%"
                        @sort-change="sortRole"
                    >
                        <el-table-column
                            prop="tenant_name"
                            class="column-table-2"
                            label="テナント名"
                            sortable="false"
                            min-width="180"
                        >
                            <template slot-scope="scope">
                <span>
                <router-link :to="{ name: 'edit-tenant', params: { tenant_id: scope.row.id }}">
                   {{ scope.row.tenant_name }}
                </router-link>
                 </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            class="column-table"
                            prop="tenant_dir"
                            label="ディレクトリ"
                            sortable="false"
                            min-width="150"
                        >
                          <template slot-scope="scope">
                            <a target="_blank" :href="scope.row.admin_url">
                              {{scope.row.tenant_dir}}
                            </a>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="tenant_status"
                            class="column-table"
                            label="ステータス"
                            sortable="false"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                {{  constants.TENANT_STATUS[scope.row.tenant_status].label }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-else class="text-center no-list-data">{{$t('el.table.emptyText')}}</div>
                    <div class="row float-right pr-5">
                        <my-pagination
                            v-if="listTenant.total"
                            :total-record="parseInt(listTenant.total)"
                            :current-page="parseInt(listTenant.current_page)"
                            :page-size-default="parseInt(listTenant.per_page)"
                            @handleCurrentChange="handleCurrentChange"
                            @handleSizeChange="handleSizeChange"
                        />
                    </div>
                </div>
            </div>
        </div>
        <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                   :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
            <template slot="header">
                <div class="textwidget text-center">
                    {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                </div>
            </template>
        </my-dialog>
    </div>
</template>
<script>
import mixinSearch from "@/mixins/searchMixin";
import * as constants from "@/core/config/constant";
import { mapActions, mapGetters } from "vuex";
import {
    GET_LIST_TENANT,
    EXPORT_TENANT_FILE_CSV
} from "@/core/services/store/mtenant.module";
import ButtonSearchMobile from "@/view/components/ButtonSearchMobile";

export default {
    name: "list-tenant",
    components: {ButtonSearchMobile},
    mixins: [mixinSearch],
    data() {
        return {
            searchForm: {
                tenant_name: "",
                tenant_status: [],
                tenant_dir: ''
            },
            options: [],
            loading: false,
            constants: constants,
            not_permisson_popup: false
        };
    },
    computed: {
        ...mapGetters(["listTenant"]),
        queryParam() {
            return this.$route.query
        },
    },
    watch: {
        async queryParam() {
            this.loading = true;
            if (Object.keys(this.queryParam).length === 0){
                this.resetDataSearch();
            }
            await this[GET_LIST_TENANT](this.queryParam);
            this.getDataFromQuery();
            this.loading = false;
        },
    },
    async created() {
        this.loading = true;

        this.getDataFromQuery();
        this.options = constants.ROLE_STATUS;
        if (!this.hasPermissonCheck('tennant_flg')){
            this.not_permisson_popup = true
        }else {
            await this[GET_LIST_TENANT](this.queryParam);
        }
        this.loading = false;
    },
    methods: {
        ...mapActions([GET_LIST_TENANT, EXPORT_TENANT_FILE_CSV]),
        getDataFromQuery() {
            this.searchForm.tenant_name = this.queryParam.tenant_name ? this.queryParam.tenant_name : "";
            this.searchForm.tenant_dir = this.queryParam.tenant_dir ? this.queryParam.tenant_dir : "";
            let arrayStatusTenant = (this.queryParam.tenant_status && this.queryParam.tenant_status.length) ? this.queryParam.tenant_status.split(',') : [];
            this.searchForm.tenant_status = arrayStatusTenant.map(item => parseInt(item));
        },
        onSearch() {
            this.getDataSearch(this.searchForm);
            this.search("tenant-list");
        },
        sortRole(sortProps) {
            this.sortColumn("list-role",sortProps);
            this.search("tenant-list");
        },
        handleSizeChange(val) {
            this.sizeChange("tenant-list", val);
        },
        handleCurrentChange(val) {
            this.pageChange("tenant-list", val);
        },
        async exportExcel(){
            let data = await this[EXPORT_TENANT_FILE_CSV](this.queryParam);
            this.exportCsvCommon('SC018_',data)
        }
    }
};
</script>
<style scoped>
.ml-10 {
    margin-left: 10rem !important;
}

</style>
